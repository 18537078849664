import { addItemConversation, changeOrderStatus, getProductionOrderData, getQuote, getWizardDocById, linkDocument, listHistoryDocs, listWizardDocs, patchWizardDoc, wizardDocApprove, wizardDocArchive } from '../api/backend'
import { getBackendApiUrl } from "../config"
import { listWizardDocConversations } from '../api/backend'
import { ArtStates, InternalArtStates, ArtworkFolder, toInternalArtState } from '../types/art-status'
import { AppPages } from '../app-pages'
import { appModel } from './app-model'
import { ArtworkItemType, HistoryDesign, WizardDocConversationWithOrderN } from '../types/api-types'
import { Url } from '../utils/url'
import axios from 'axios'
import { AxiosError } from '../types/error-types'
import { FileRole } from '../pages/design-detail/comps/fileUpload'
import { getAdminId } from '../api/network'

/*
All Model Mutations should be done in Actions
*/

//Initialize the applcation
export async function init() {
  try {
    const url = new Url(window.location.href)
    const sourceType = url.getParam('SourceType')
    const docType = url.getParam('DocType')
    const docNo = url.getParam('DocNo')
    const docLineNo = url.getParam('DocLineNo')
    const adminId = getAdminId()
    const customerId = url.getParam('customerId')
    const artId = url.getParam('artId')

    appModel.orderNo = docNo
    appModel.lineNo = docLineNo
    appModel.adminId = adminId
    appModel.customerId = customerId
    appModel.artId = artId

    let linkSucceeded = true
    if (sourceType === 'Sale' && docType == 'Order') {
      const result = await linkDesign({ artId, docNo, docLineNo })
      linkSucceeded = result
    }

    if (!linkSucceeded) {
      appModel.page = AppPages.InitFail;
      throw new Error('Error linking design');
    }
    else if (sourceType === 'Production' && docNo) {

      const result = await Promise.all([
        updateDesignDetails(),
        getProductionOrderData(docNo)
      ])

      const data = result[1]
      appModel.prodLineItems = data

      if (data && data.length > 0 && data.some(item => item.itemno_ === "_UC_MAX")) {
        appModel.page = AppPages.DetailsUCMProduction
      } else {
        appModel.page = AppPages.DetailsProduction
      }

    } else if (sourceType === 'Sale') {

      await updateDesignDetails()
      appModel.page = AppPages.DetailsArtist

    } else if (artId) {

      await updateDesignDetails()
      appModel.page = AppPages.DetailsCustomer

    } else {

      await updateDashboard()
      appModel.page = AppPages.Dashboard
    }
  } catch (error) {
    handleError(error, "initialization");
  }
}

export async function updateDashboard(_?: {
  artState?: ArtStates,
  searchText?: string,
  pageIndex?: number,
  archived?: boolean  
}) {
  try {
    if (_ && _.pageIndex)
      appModel.pageIndex = _.pageIndex

    if (_ && _.artState) {
      // Reset the page if the filter has changed
      if (appModel.artStatusFilter !== _.artState)
        appModel.pageIndex = 0

      appModel.artStatusFilter = _.artState
    }

    if (_ && typeof _.searchText === "string") {
      // Reset the page if the filter has changed
      if (appModel.searchText !== _.searchText)
        appModel.pageIndex = 0

      appModel.searchText = _.searchText
    }

    if (appModel.artStatusFilter === ArtStates.HISTORY) {
      appModel.isPageLoading = true
      const pageIndex = appModel.pageIndex > 0 ? appModel.pageIndex : appModel.pageIndex + 1

      const result = await listHistoryDocs(
        pageIndex,
        appModel.pageSize,
        appModel.customerId ?? undefined,
        appModel.searchText || undefined
      )

      appModel.historyDocs = result.data
      appModel.totalDesigns = result.totalFieldsAffected
    } else {
      appModel.isPageLoading = true

      const result = await listWizardDocs(
        appModel.pageIndex,
        appModel.pageSize,
        toInternalArtState(appModel.artStatusFilter),
        appModel.searchText,
        appModel.customerId ?? undefined,
        _?.archived 
      )

      appModel.designDocs = result.data
      appModel.totalDesigns = result.totalFieldsAffected
    }
  } catch (error) {
    handleError(error, "dashboard update");
  } finally {
    appModel.isPageLoading = false;
  }
}


function handleError(error: unknown, context: string) {
  if (axios.isAxiosError(error) && error.response) {
    const axiosError = error as AxiosError;
    const errorMessage = axiosError.response?.data?.error || axiosError.message;
    console.error(`Axios error during ${context}:`, errorMessage);
    throw new Error(errorMessage);
  } else {
    console.error(`Error during ${context}:`, error);
    throw error;
  }
}

export async function updateDesignDetails() {
  try {
    const artId = appModel.artId ?? '';

    const results = await Promise.all([
      getWizardDocById(artId),
      listWizardDocConversations(artId)
    ]);

    appModel.activeDesignDoc = results[0];
    appModel.activeConversation = results[1];
  } catch (error) {
    handleError(error, "updating design details");
  }
}

export async function openHistoryDesign(design: HistoryDesign) {
  appModel.activeHistoryDoc = design
  appModel.page = AppPages.DetailsHistory
}

export async function uploadFile(designId: string, selectedFile: File, artType: ArtworkFolder, fileRole: FileRole): Promise<ArtworkItemType> {
  const server = getBackendApiUrl();
  const formData = new FormData();
  formData.append('file', selectedFile);

  const response = await fetch(`${server}/upload`, {
    method: 'POST',
    body: formData,
  });

  if (response.ok) {
    const data = await response.json(); // if the response returns a JSON object
    const { Model } = data;
    if (!Model) throw new Error("Missing Model")
    const { Id: fileId } = Model as { Id: string }

    console.log('File uploaded successfully:', fileId);

    if (!fileId) throw new Error("Missing FileId")

    // Update the wizard doc with the new file url and status
    const currentDoc = await getWizardDocById(designId);
    let currentArt = currentDoc[artType];

    if (!currentArt) {
      currentArt = [];
    }

    //TODO: need to add switch in UI to select whether the upload is DefaultFile or FlipType
    const newArt: ArtworkItemType = {
      userFileConfig: "stahls",
      userFileId: fileId,
      date: new Date().toISOString(),
      fileRole: fileRole ?? "DefaultFile"
    }; // Convert Date to ISO string to match the expected date string format

    currentArt.push(newArt);

    await patchWizardDoc(designId, { [artType]: [newArt] })

    return newArt;
  } else {
    throw new Error("Failed to upload file")
  }
}

export async function approveDesign(designId: string) {
  return await wizardDocApprove(designId)
}

export async function archiveDesign(designId: string) {
  return await wizardDocArchive(designId)
}


export async function addDesignComment(_: {
  userToken: string,
  message: string,
  designId: string,
  lastMessageId: number,
  docNo: string,
  lineNo: number,
  statusID: string
  updateStatus?: boolean;
}) {
  const updateStatus = _.updateStatus ?? true;

  const activityItem: WizardDocConversationWithOrderN = {
    userDataToken: _.userToken,
    message: JSON.stringify({
      message: _.message
    }),
    docId: _.designId,
    lastMessageId: _.lastMessageId,
    orderN: _.docNo
  };

  // Call the function to add the conversation item
  const updatedConversation = await addItemConversation(activityItem);
  console.log(updatedConversation);


  if (updateStatus) {
    // transition to user
    await changeOrderStatus(
      _.designId,
      _.statusID
    );
  }

  await updateDesignDetails()
}

export async function updateDesignArtStatus(designId: string, newStatus: InternalArtStates) {
  await changeOrderStatus(
    designId,
    newStatus
  )

  await updateDesignDetails()
}

export async function setProofToReady(designId: string) {
  await changeOrderStatus(
    designId,
    InternalArtStates.PEND_C)

  await updateDesignDetails()
}

async function linkDesign(_: { artId?: string, docNo?: string, docLineNo?: string }) {
  if (_.artId && _.docNo && _.docLineNo) {
    const docId = _.artId
    const lineNo = Number.parseInt(_.docLineNo)
    if (!Number.isNaN(lineNo)) {
      try {
        const resp = await linkDocument(docId, _.docNo, lineNo);
        return docId == resp.designId && resp.orderN == _.docNo && resp.lineN == lineNo
      }
      catch (e) {
        console.error("Error linking design:", e);
        return false
      }
    }

    return true
  }

  return true
}

export async function getPriceBreaks(wizardData:any) {
  const quote = await getQuote(wizardData)
  return { PriceBreaks: quote?.PriceBreaks, Multiple: quote?.Multiple }
}