import { Header } from "../../components/list/header"
import { Pager } from "../../components/list/pager"
import { ITEMS_PER_PAGE } from "../../config"
import SkeletonLoader from "../../components/list/skeletonAnimation"
import { getUrlParams } from "../../utils/general"
import { useSnapshot } from "valtio"
import appModel from "../../models/app-model"
import { HistoryDesign } from "../../types/api-types"
import { openHistoryDesign } from "../../models/app-actions"
import { productMapping } from "../../types/product-types"
import NoResults from "../../components/noResults/noResults"
import { useState } from "react"

// Styles
import statesStyles from "./art-filter.module.css";
import styles from './history-list.module.css';

interface HistoryListPageProps { }

const HistoryList: React.FC<HistoryListPageProps> = () => {
  const appSnap = useSnapshot(appModel);
  const docs = appSnap.historyDocs;
  const totalItems = appSnap.totalDesigns;
  const isLoading = appSnap.isPageLoading;
  const loaderArray = [...Array(12).keys()];
  const urlParams = getUrlParams();
  const customerId = appSnap.customerId ?? "";
  const searchText = appSnap.searchText || "";
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(false);

  const handleMenuClick = (value: boolean) => {
    setSideBarOpen(value);
  }

  const handleLoadHistoryDoc = async (item: HistoryDesign) => {
    try {
      openHistoryDesign(item);
    } catch (err) {
      console.log(err);
    }
  };

  const handleViewDoc = async (item: HistoryDesign) => {
    try {
      openHistoryDesign(item);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={sideBarOpen ? styles.appContainer : ""}>
      {sideBarOpen && <div className={styles.overlay}></div>}
      <div className={styles.wrapper}>
        <Header
          customerId={customerId}
          onMenuToggle={(e) => handleMenuClick(e)} />

        <Pager
          totalItems={totalItems}
          itemsPerPage={ITEMS_PER_PAGE}
          searchText={searchText}
        />

        {!isLoading && docs.length === 0 && (
          <NoResults
            isSearch={searchText !== ''}
            searchText={searchText}
          />
        )}

        <ul role="list" className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-3 md:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {!isLoading ? docs?.map((doc: HistoryDesign) => {
            return (
              <li key={doc.id} className="relative p-2">
                <div className={styles.card}>
                  <a href={""} className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                    <img
                      src={doc.productionArtworkPreviewUrl}
                      alt=""
                      className="pointer-events-none object-cover group-hover:opacity-75" />
                    <button
                      type="button"
                      className="absolute inset-0 focus:outline-none"
                      onClick={(e) => {
                        e.preventDefault();
                        handleLoadHistoryDoc(doc);
                      }}>
                      <span className="sr-only">View details for {doc.id}</span>
                    </button>
                  </a>
                  <div className={[styles.statusBar, statesStyles['historyColor']].join(' ')}></div>
                  <div className={styles.historyBody}>
                    <div className={styles.cardDetails}>
                      <span><b>Artwork Name:</b></span>
                      <span>{doc.wizardData.artName ?? "--"}</span>
                    </div>
                    <div className={styles.cardDetails}>
                      <span><b>Design ID:</b></span>
                      <span>{doc.id ?? "--"}</span>
                    </div>
                    <div className={styles.cardDetails}>
                      <span><b>Size:</b></span>
                      <span>{doc.wizardData.artHeight ?? "--"} x {doc.wizardData.artWidth ?? "--"}</span>
                    </div>
                    <div className={styles.cardDetails}>
                      <span><b>Product Type:</b></span>
                      <span>{productMapping[doc.wizardData.productType] ?? "--"}</span>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-3 p-2 pb-6">
                    <a className={styles.btnWrapper} href={`?${urlParams}`}>
                      <button
                        type="button"
                        className={`${styles.orangeBtn} flex flex-row gap-1 text-white hover:bg-blue-500`}
                        onClick={(e) => {
                          e.preventDefault();
                          handleViewDoc(doc)
                        }}>
                        VIEW
                      </button>
                    </a>
                  </div>
                </div>
              </li>
            );
          })
            :
            loaderArray?.map((i) => (
              <li key={i} className="relative">
                <SkeletonLoader />
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
}

export default HistoryList;