import React from 'react';

// Styles
import styles from './imagePreview.module.css';

interface ImagePreviewProps {
  isOpen: boolean;
  imageSrc: string;
  onClose: () => void;
}

function CloseIcon() {
  return (
    <button type="button" className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
      <span className="sr-only">Close menu</span>
      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
  );
}

const ImagePreview: React.FC<ImagePreviewProps> = ({ isOpen, imageSrc, onClose }) => {

  return isOpen ? (
    <div className={styles.modalOverlay}>
      <div className={styles.modalHeader}>
        <button className={styles.closeBtn} onClick={onClose} aria-label="Close">
          <CloseIcon />
        </button>
      </div>
      <div className={`${styles.modalContainer} relative w-auto my-auto mx-auto max-w-4xl`}>
        <div className={`${styles.modalWrapper} border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}>
          <div className={styles.modalContent}>
            <div className={styles.optionsWrapper}>
              <img
                src={imageSrc}
                alt="imagePreview"
                className={styles.imagePreview}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default ImagePreview;
